import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DateTime } from 'luxon';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { MainContentBox, MainDataGridNoRowHover, DataGridListScrollBox, PageTitleToolbarGrid } from '../../util/SharedStyles';
import { GridRenderCellParams, GridValueGetterParams, GridColumns, GridRowModel, GridOverlay, GridSortModel, useGridApiContext, GridSortItem, GridSelectionModel, GridToolbarContainer, GridCsvExportOptions, GridApi } from '@mui/x-data-grid-pro';
import { Button, CircularProgress, Dialog, Link, Tooltip, Typography, styled } from '@mui/material';
import { debounce } from 'lodash';

import { useTitle, capitalizeWithUnderscoreRemoval, PremadeErrorLogFilterSet } from '../../util/Common';
import { ToastSeverity, Viewer, defaultPageSize, eventLogErrorsFilterDate } from '../../util/Constants';
import { getShortDateString, getTimeString } from '../../util/DateTimeUtility';

import PageTitleBar from '../../components/PageTitleBar';
import ErrorMessage from '../../components/ErrorMessage';
import NoResultsMessage from '../../components/NoResultsMessage';
import { clearState, selectError, selectRequestsInFlight, selectErrorLogList, selectErrorLogPagingResult, captureUpdateLogsStatus, clearError, selectUpdateLogsStatus } from './ErrorLogsSlice';
import { fetchErrorLogList, updateTransactionErrorStatus } from './ErrorLogsActions';
import MainDataGridLoadingSkeleton from '../../components/MainDataGridLoadingSkeleton';
import ErrorLogsFilterBar from '../../components/filters/ErrorLogsFilterBar';
import FiltersButton from '../../components/buttons/FiltersButton';
import GridCellDualVert from '../../components/listItems/GridCellDualVert';
import { GetTransactionErrorsInput, TransactionErrorOrderByInput, OrderDirection, ProcessName, RequestResult, ExceptionStatus } from '../../gql-types.generated';
import { viewerCanEdit } from '../../util/ViewerUtility';
import ConfirmationPrompt from '../../components/ConfirmationPrompt';
import { setToastConfig } from '../EDIContainer/EDIContainerSlice';

const DetailsLink = styled(Link)((props) => ({
    cursor: 'pointer',
}));

const ToolbarButton = styled(Button)`
     margin-right: 4px;
`;

interface ErrorLogsProps {
    viewer: Viewer | undefined;
    autoSearch?: boolean;
}

const ErrorLogs: React.FC<ErrorLogsProps> = (props) => {
    const { premadeQuery } = useParams();
    const { viewer, autoSearch=false } = props;
    const dispatch = useAppDispatch();    
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [contentAreaHeight, setContentAreaHeight] = useState('200px'); //need an actual default height so grid doesn't error on load
    const [openResolveErrorsConfirm, setOpenResolveErrorsConfirm] = useState(false);
    const [resolveErrorStatusErrorMessage, setResolveErrorStatusErrorMessage] = useState('');
    const [isSettingErrorsResolved, setIsSettingErrorsResolved] = useState(false);
    const [filtersOpen, setFiltersOpen] = useState(true);
    const [filtersCleared, setFiltersCleared] = useState(false);
    const [filterClientName, setFilterClientName] = useState<string | undefined>(undefined);
    const [filterPartnerName, setFilterPartnerName] = useState<string | undefined>(undefined);
    const [filterPO, setFilterPO] = useState<string | undefined>(undefined);
    const [filterDocNumber, setFilterDocNumber] = useState<string | undefined>(undefined);
    const [filterProcessName, setFilterProcessName] = useState<ProcessName | undefined>(undefined);
    const [filterStatus, setFilterStatus] = useState<ExceptionStatus | undefined>(undefined);
    const [filterDateFrom, setFilterDateFrom] = useState<DateTime | undefined>(undefined);
    const [filterDateTo, setFilterDateTo] = useState<DateTime | undefined>(undefined);
    const [filterDirection, setFilterDirection] = useState<string | undefined>(undefined);
    const [filterDocumentType, setFilterDocumentType] = useState<string | undefined>(undefined);
    const [filterFileName, setFilterFileName] = useState<string | undefined>(undefined);
    const [filterCount, setFilterCount] = useState(0);
    const [premadeFilter, setPremadeFilter] = useState<PremadeErrorLogFilterSet | undefined | null>(null);
    const [errorLogRows, setErrorLogRows] = useState<GridRowModel[] | undefined>(undefined);
    const [debounceOn, setDebounceOn] = useState(false);
    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>();
    const [sortFirstLoad, setSortFirstLoad] = useState(true);
    const [sortModel, setSortModel] = useState<GridSortModel>([
        {
            field: 'documentSendReceiveDate',
            sort: 'desc',
        },
    ]);
    const [serverSortModel, setServerSortModel] = useState<TransactionErrorOrderByInput[] | undefined>([{
        createdTime: OrderDirection.Desc
    } as TransactionErrorOrderByInput]);

    const pageSize = defaultPageSize;

    const errorLogs = useAppSelector(selectErrorLogList);
    const error = useAppSelector(selectError);
    const requestsInFlight = useAppSelector(selectRequestsInFlight);
    const errorLogsPagingResult = useAppSelector(selectErrorLogPagingResult);
    const updateStatus = useAppSelector(selectUpdateLogsStatus);

    const canEdit = viewerCanEdit(viewer);
    
    useTitle("Error Logs");

    const debounceOnSortingChanged = useRef(
        debounce((servSortModel) => {
            onSortModelChanged(servSortModel);
        }, 1000)
    ).current;

    useEffect(() => {
        dispatch(clearState());
        setTimeout(() => {
            setDebounceOn(true);
        }, 200);
        if (premadeQuery) {
            applyPreset();
        } else {
            setPremadeFilter(undefined);
        }
    }, []);

    useEffect(() => {
        setErrorLogRows(getErrorLogRows());
        if (!errorLogs && requestsInFlight > 0) {
            setIsLoading(true);
        } else if (requestsInFlight > 0) {
            setIsLoading(true);
        } else {
            setIsLoading(false);
        }
    }, [errorLogs, requestsInFlight]);

    useEffect(() => {
        if (updateStatus?.result === RequestResult.Success) {
            dispatch(setToastConfig({
                message: updateStatus.message as string,
                severity: ToastSeverity.Success
            }));
            onResolveErrorDialogClose();
            // Refresh list to bring in potential updates
            loadPage(undefined);
        } else if (updateStatus?.result === RequestResult.Fail) {
            dispatch(setToastConfig({
                message: updateStatus.message as string,
                severity: ToastSeverity.Error
            }));
            onResolveErrorDialogClose();
        }
    }, [updateStatus?.result]);


    useEffect(() => {
        // we have content, so lets properly size that content area        
        const titleHeight = document.getElementById('error-logs-title-comp')?.clientHeight || 0;
        // if filters are open, include that in the calculation
        let filterBarHeight = 0;
        if (filtersOpen) {
            filterBarHeight = document.getElementById('error-logs-filter-bar')?.clientHeight || 0;
        }
        let totalHeaderAreaHeight = titleHeight + filterBarHeight;
        if (totalHeaderAreaHeight > 0) {
            setContentAreaHeight(`calc(100% - ${totalHeaderAreaHeight}px)`);
        }
    }, [errorLogs, filtersOpen]);

    useEffect(() => {
        if (errorLogs) {
            let numFilters = 0;

            if (filterClientName && filterClientName.length > 0) {
                numFilters += 1;
            }
            if (filterDirection && filterDirection.length > 0) {
                numFilters += 1;
            }
            if (filterDocumentType && filterDocumentType.length > 0) {
                numFilters += 1;
            }
            if (filterPartnerName && filterPartnerName.length > 0) {
                numFilters += 1;
            }
            if (filterPO && filterPO.length > 0) {
                numFilters += 1;
            }
            if (filterDocNumber && filterDocNumber.length > 0) {
                numFilters += 1;
            }
            if (filterStatus && filterStatus.length > 0) {
                numFilters += 1;
            }
            if (filterProcessName && filterProcessName.length > 0) {
                numFilters += 1;
            }
            if (filterFileName && filterFileName.length > 0) {
                numFilters += 1;
            }
            if (filterDateFrom) {
                numFilters += 1;
            }
            if (filterDateTo) {
                numFilters += 1;
            }

            setFilterCount(numFilters);
        }
    }, [errorLogs, filterClientName, filterDirection, filterDocumentType, filterPartnerName, filterPO, filterDocNumber, filterStatus, filterProcessName, filterDateFrom, filterDateTo, filterFileName]);

    useEffect(() => {
        return () => {
            debounceOnSortingChanged.cancel();
        };
    }, [debounceOnSortingChanged]);

    const getSortRefreshInput = () => {
        return {
            after: undefined,
            limit: pageSize,
            clientName: filterClientName,
            direction: filterDirection,
            documentType: filterDocumentType,
            order: serverSortModel,
            partnerName: filterPartnerName,
            pO: filterPO,
            documentId: filterDocNumber,
            status: filterStatus,
            processName: filterProcessName,
            dateFrom: filterDateFrom,
            dateTo: filterDateTo,
            originalFileName: filterFileName
        } as GetTransactionErrorsInput
    }

    useEffect(() => {
        if (!sortFirstLoad) {
            const sortRefreshData = getSortRefreshInput();
            if (debounceOn !== true) {
                onSortModelChanged(sortRefreshData);
            } else {
                debounceOnSortingChanged(sortRefreshData);
            }
        } else {
            setSortFirstLoad(false);
        }
    }, [serverSortModel]);

    const getSelectedRowLog = useCallback((rowId: string) => () => {
        if (rowId && errorLogs?.length) {
            let log = errorLogs.find(l => l.id === rowId);
            return log;
        }
        return undefined;
    }, [errorLogs]);

    const getErrorLogRows = () => {
        if (errorLogs && errorLogs.length > 0) {
            return errorLogs.map((errorLog) => {
                const {
                    id,
                    clientId,
                    clientName,
                    documentReferenceId,
                    purchaseOrderNumber,
                    documentType,
                    direction,
                    createdTime,
                    processName,
                    documentId,
                    originalFileName,
                    status,
                    partnerId,
                    partnerName,
                    rawMessage,
                } = errorLog;
                
                return {
                    _raw: errorLog,
                    id,
                    clientName,
                    clientId,
                    documentNumber: documentId,
                    PONumber: purchaseOrderNumber,
                    documentType,
                    direction,
                    documentSendReceiveDate: createdTime,
                    referenceId: documentReferenceId,
                    partnerName,
                    partnerId,
                    fileName: originalFileName,
                    processName,
                    status,
                    rawMessage
                } as GridRowModel;
            }) as GridRowModel[];
        } else {
            return [];
        }
    };

    const onRowClientNameClick = (id: string | undefined) => {
        if (id) {
            let route = "/client/" + id;
            navigate(route);
        }
    };

    const onRowPartnerNameClick = (id: string | undefined) => {
        if (id) {
            let route = "/partner/" + id;
            navigate(route);
        }
    };

    // const onRowDocumentTypeClick = (clientPartnerId: string | undefined) => {
    //     // navigate to the clientPartner to allow the user to view the associated transaction/documentType
    //     if (clientPartnerId) {
    //         let route = "/clientpartner/" + clientPartnerId;
    //         navigate(route);
    //     }
    // };

    const onRowStatusClick = (rowId: string) => {
        if (rowId) {
          navigate('/errorlog/' + rowId);
        }
    };
 
    const errorLogColumns = useMemo<GridColumns<GridRowModel>>(
        () => [
            {
                headerName: 'CLIENT',
                field: 'clientName',
                flex: 2,
                minWidth: 180,
                cellClassName: "ediDataGridCellFirstChild",
                sortable: false,
                renderCell: (params: GridRenderCellParams) => {
                    const { value, row } = params;
                    const clientId = row.clientId;
                    
                    if (clientId) {
                        return (
                            <Link
                                component="button"
                                className="ediDataGridCellFirstChild ediDataGridCellOverflowHidden"
                                onClick={() => {
                                    onRowClientNameClick(clientId);
                                }}
                            >
                                {value}
                            </Link>
                        );
                    } else {
                        return value;
                    }
                },
            }, {
                headerName: 'DOC #',
                field: 'documentNumber', //documentId
                flex: 1,
                minWidth: 100,
                sortable: true,
            }, {
                headerName: 'PO #',
                field: 'PONumber',
                flex: 2,
                minWidth: 180,
                sortable: true,
            }, {
                headerName: 'I/O',
                field: 'direction',
                width: 60,
                sortable: false,
            }, {
                headerName: 'TYPE',
                field: 'documentType',
                width: 90,
                sortable: false,
                renderCell: (params: GridRenderCellParams) => {
                    const { value, row } = params;
                    //const clientPartnerId = row.clientPartnerId;
                    
                    // if (clientPartnerId) {
                    //     return (
                    //         <Link
                    //             component="button"
                    //             variant="body2"
                    //             className="ediDataGridCellOverflowHidden"
                    //             onClick={() => {
                    //                 onRowDocumentTypeClick(clientPartnerId);
                    //             }}
                    //         >
                    //             {value}
                    //         </Link>
                    //     );
                    // } else {
                        return value;
                    //}
                },
            }, {
                headerName: 'DOC S/R',
                field: 'documentSendReceiveDate', //createdTime
                width: 114,
                sortable: true,
                type: 'dateTime',
                renderCell: (params: GridRenderCellParams) => {
                    const { value } = params;
                    let dateValue = value ? getShortDateString(value) : undefined;
                    let timeValue = value ? getTimeString(value, { includeSeconds: true }) : undefined;
                    return (
                        <GridCellDualVert header={dateValue} sub={timeValue} />
                    )
                },
            }, {
                headerName: 'REF #',
                field: 'referenceId',
                flex: 1,
                minWidth: 180,
                sortable: true,
            }, {
                headerName: 'PARTNER',
                field: 'partnerName',
                minWidth: 140,
                flex: 2,
                sortable: false,
                renderCell: (params: GridRenderCellParams) => {
                    const { value, row } = params;
                    const partnerId = row.partnerId;
                    if (partnerId) {
                        return (
                            <Link
                                component="button"
                                variant="body2"
                                className="ediDataGridCellOverflowHidden"
                                onClick={() => {
                                    onRowPartnerNameClick(partnerId);
                                }}
                            >
                                {value}
                            </Link>
                        );
                    } else {
                        return value;
                    }
                },
            }, {
                headerName: 'FILE NAME',
                field: 'fileName',
                flex: 2,
                minWidth: 200,
                sortable: false,
            }, {
                headerName: 'PROCESS NAME',
                field: 'processName',
                flex: 2,
                minWidth: 180,
                sortable: false,
                valueGetter: (params: GridValueGetterParams) => {
                    const { value } = params;
                    return capitalizeWithUnderscoreRemoval(value) ?? '';
                },
            }, {
                headerName: 'STATUS',
                field: 'status',
                flex: 1,
                minWidth: 120,
                sortable: false,
                renderCell: (params: GridRenderCellParams) => {
                    const { value, row } = params;
                    const displayValue = capitalizeWithUnderscoreRemoval(value, ' ');
                    if (value === ExceptionStatus.Error) {
                        var truncatedMessage = row.rawMessage && row.rawMessage.length > 300 ? row.rawMessage.substring(0, 300) + "..." : row.rawMessage;
                        return (
                            <Tooltip
                                title={<Typography style={{fontSize: '14px'}}>{truncatedMessage}</Typography>}
                                placement='left-start'
                            >
                                <DetailsLink onClick={() => {onRowStatusClick(row.id)}} color="error" >{displayValue}</DetailsLink>
                            </Tooltip>
                        )
                    } else if (value === ExceptionStatus.ResolvedError) {
                        return (
                            <DetailsLink onClick={() => {onRowStatusClick(row.id)}} color="primary" >{displayValue}</DetailsLink>
                        )
                    }
                    else {
                        return displayValue;
                    }
                },
            }, {
                headerName: 'MESSAGE',
                field: 'rawMessage',
                hide: true,
            },

        ],
        [],
    );

    const GetApiRef = () => {
        return useGridApiContext();
    }
    const loadingOverlay = () => {
        return (
            <GridOverlay>
                {error && (
                    <ErrorMessage title='Unable to load the Error Logs' error={error}></ErrorMessage>
                )}
                {!error && (errorLogs && errorLogs.length > 0 && requestsInFlight > 0) && (
                    <CircularProgress aria-label={'progress spinner'} key={'logs-spinner'} size={42} sx={{ zIndex: 1 }} />
                )}
                {!error && (!errorLogs) && (
                    <MainDataGridLoadingSkeleton apiRef={GetApiRef()} rowBottomMargin={6} />
                )}
            </GridOverlay>
        );
    };

    const noRowsOverlay = () => {
        return (
            <GridOverlay>
                {error && (
                    <ErrorMessage title='Unable to load the Error Logs' error={error}></ErrorMessage>
                )}
                {!error && (errorLogs && errorLogs.length <= 0 && filterCount > 0) && (
                    <NoResultsMessage topMargin={6} message="Try different filters to see results." />
                )}
            </GridOverlay>
        );
    };

    const getServerSortEntryFromGridSortEntry = (entry: GridSortItem) => {
        let newModel = {} as TransactionErrorOrderByInput;
        switch (entry.field) {
            case 'documentSendReceiveDate':
                newModel.createdTime = entry.sort === 'asc' ? OrderDirection.Asc : OrderDirection.Desc;
                break;
            case 'documentNumber':
                newModel.documentId = entry.sort === 'asc' ? OrderDirection.Asc : OrderDirection.Desc;
                break;
            case 'referenceId':
                newModel.documentReferenceId = entry.sort === 'asc' ? OrderDirection.Asc : OrderDirection.Desc;
                break;
            // case 'documentType':
            //     newModel.documentType = entry.sort === 'asc' ? OrderDirection.Asc : OrderDirection.Desc;
            //     break;
            case 'PONumber':
                newModel.purchaseOrderNumber = entry.sort === 'asc' ? OrderDirection.Asc : OrderDirection.Desc;
                break;
        }
        return newModel;
    };

    const createdAndSetServerSortModel = (model: GridSortModel) => {
        if (model && model.length > 0) {
            let newArray = [] as TransactionErrorOrderByInput[];
            let i = 0;
            for (i; i < model.length; ++i) {
                newArray.push(getServerSortEntryFromGridSortEntry(model[i]));
            }
            setServerSortModel(newArray);
        } else {
            setServerSortModel(undefined);
        }

    };

    const onSortModelChange = (model: GridSortModel) => {
        createdAndSetServerSortModel(model);
        setSortModel(model);
    };

    const onFiltersClick = () => {
        // Show/hide filters bar
        setFiltersOpen(!filtersOpen);
        // reset filters cleared to false since not clicking close button on bar at this point
        setFiltersCleared(false);
    };

    const onFilterBarClose = () => {
        // set filters as cleared
        setFiltersCleared(true);
        setFilterCount(0);
        // we don't want to close the filter bar since client is required
        setFiltersOpen(true);
    };

    const onSortModelChanged = (refreshInput: GetTransactionErrorsInput) => {
        dispatch(clearState());
        dispatch(fetchErrorLogList(refreshInput));
    };

    const onSelectionModelChange = (currentSelectionModel: GridSelectionModel) => {
        setSelectionModel(currentSelectionModel);
    };

    const loadPage = (endEdge: string | undefined) => {
        dispatch(fetchErrorLogList({
            after: endEdge,
            limit: pageSize,
            clientName: filterClientName,
            direction: filterDirection,
            documentType: filterDocumentType,
            order: serverSortModel,
            partnerName: filterPartnerName,
            purchaseOrderNumber: filterPO,
            documentId: filterDocNumber,
            status: filterStatus,
            processName: filterProcessName,
            dateFrom: filterDateFrom,
            dateTo: filterDateTo,
            originalFileName: filterFileName
        }));
    };

    const handlePageLoad = () => {
        if (!errorLogsPagingResult) {
            return;
        }
        if (!errorLogsPagingResult.cursor?.nextPage) {
            return;
        }

        loadPage(errorLogsPagingResult.cursor.nextPage);
    };

    const refreshFilters = (
        filterClient: string | undefined,
        filterIO?: string | undefined,
        filterDocType?: string | undefined,
        filterPartner?: string | undefined,
        filterPONum?: string | undefined,
        filterDocNum?: string | undefined,
        filterStatusVal?: ExceptionStatus | undefined,
        filterProcess?: ProcessName | undefined,
        filterDocDateFrom?: DateTime | undefined,
        filterDocDateTo?: DateTime | undefined,
        filterOriginalFileName?: string | undefined,
    ) => {
        setFilterClientName(filterClient);
        setFilterDirection(filterIO);
        setFilterDocumentType(filterDocType);
        setFilterDocNumber(filterDocNum);
        setFilterPartnerName(filterPartner);
        setFilterPO(filterPONum);
        setFilterStatus(filterStatusVal);
        setFilterProcessName(filterProcess);
        setFilterDateFrom(filterDocDateFrom);
        setFilterDateTo(filterDocDateTo);
        setFilterFileName(filterOriginalFileName);
        dispatch(clearState());
        
        dispatch(fetchErrorLogList({
            after: undefined,
            limit: pageSize,
            clientName: filterClient,
            direction: filterIO,
            documentType: filterDocType,
            order: serverSortModel,
            partnerName: filterPartner,
            purchaseOrderNumber: filterPONum,
            documentId: filterDocNum,
            status: filterStatusVal,
            processName: filterProcess,
            dateFrom: filterDocDateFrom,
            dateTo: filterDocDateTo,
            originalFileName: filterOriginalFileName
        }));
       
    };

    const onDialogClose = () => {
        // Clear error on close.
        dispatch(clearError());
    };
    
    const getSelectionRows = () => {
        if (selectionModel && selectionModel.length > 0) {
            return selectionModel.map(rowId => rowId.toString());
        }
    };

    const resolveSelectedErrorStatusRows = () => {
        setOpenResolveErrorsConfirm(true);
    };

    const onResolveErrorDialogClose = () => {
        setOpenResolveErrorsConfirm(false);
        onDialogClose();
        dispatch(captureUpdateLogsStatus());
        setIsSettingErrorsResolved(false);
        setResolveErrorStatusErrorMessage('');
        setSelectionModel([]);
    };

    const onResolveErrorsConfirm = () => {
        dispatch(captureUpdateLogsStatus());

        let ids = getSelectionRows();
        // filter out any rows that are not in error status
        ids = ids?.filter(id => {
            let row = getSelectedRowLog(id);
            return row && row()?.status === ExceptionStatus.Error;
        });

        // resolve the selected error status rows
        if (ids && ids.length > 0) {
            setIsSettingErrorsResolved(true);
            dispatch(updateTransactionErrorStatus(ids, ExceptionStatus.ResolvedError));
            
        } else {
            onResolveErrorDialogClose();
        }
    };

    const applyPreset = () => {
        if (premadeQuery) {
            if (premadeQuery === "errors") {
                applyPresetErrorsFilter();
            } else if (premadeQuery === "map-errors") {
                applyPresetErrorsFilter(true);
            }
        }
    };
    
    const applyPresetErrorsFilter = (isMapping?: boolean) => {
        setPremadeFilter({
            filterStatusVal: ExceptionStatus.Error,
            filterDocDateFrom: eventLogErrorsFilterDate,
            filterProcess: isMapping === true ? ProcessName.MapDataValidation : undefined
        } as PremadeErrorLogFilterSet);
    };

    const getResolveErrorsConfirmationMessage = () => {
        let message = "Are you sure you want to resolve the selected 'Error' status logs?";
        if (selectionModel && selectionModel.length > 0) {
            let nonErrorRows = selectionModel.filter(rowId => {
                let row = getSelectedRowLog(rowId.toString());
                return row && row()?.status !== ExceptionStatus.Error;
            });
            if (nonErrorRows.length > 0) {
                message = "Some selected logs are not in Error status. \nDo you still want to resolve selected logs (if any) \nthat are of status 'Error'?";
            }
        }
        return message;
    };

    const handleExport = (options: GridCsvExportOptions, apiRef: React.MutableRefObject<GridApi>) => {
        // use the apiRef to directly call the export function
        // doing this instead of using the export button in the grid toolbar
        // to prerror having to choose the download option from the button dropdown
        apiRef?.current.exportDataAsCsv(options);
    };
        
    const gridToolbar = () => {
        let apiRef=GetApiRef();
        return (
            <GridToolbarContainer>
                {canEdit &&
                <ToolbarButton
                    title="Resolve Selected Errors"
                    aria-label="Resolve Errors"
                    size="small"
                    onClick={resolveSelectedErrorStatusRows}
                    color='primary'
                    disabled={!(selectionModel && selectionModel.length > 0)}
                >
                    Resolve Error
                </ToolbarButton>
                }
                <ToolbarButton
                    title="Export to CSV"
                    aria-label="Export"
                    size="small"
                    onClick={() => handleExport({ allColumns: true }, apiRef)}
                    color='primary'
                    disabled={isLoading || !errorLogRows || errorLogRows.length === 0}
                >
                    Export
                </ToolbarButton>
            </GridToolbarContainer>
        );
   
    };

    return (
        <MainContentBox>
            <PageTitleBar text='Error Logs' id="error-logs-title-comp">
                <PageTitleToolbarGrid item>
                    <FiltersButton
                        onClick={onFiltersClick}
                        filterCount={filterCount}
                        filtersCleared={filtersCleared}
                        disabled={isLoading}
                        aria-label="filter button"
                        data-cy="filters"
                    />
                </PageTitleToolbarGrid>
            </PageTitleBar>
            <ErrorLogsFilterBar
                id="error-logs-filter-bar"
                visible={filtersOpen}
                loading={isLoading}
                viewer={viewer}
                autoSearch={autoSearch}
                premadeSet={premadeFilter}
                onFilterChanged={refreshFilters}
                onClose={onFilterBarClose}
            />
            <DataGridListScrollBox scrollheight={contentAreaHeight}>
                <MainDataGridNoRowHover
                    loading={isLoading}
                    rowHeight={52}
                    aria-label="Error Logs List"
                    hideFooter
                    disableColumnMenu
                    disableColumnFilter
                    checkboxSelection={canEdit}
                    rows={errorLogRows ?? []}
                    columns={errorLogColumns}
                    sortingOrder={['asc', 'desc']}
                    sortModel={sortModel}
                    sortingMode="server"
                    onSortModelChange={onSortModelChange}
                    selectionModel={selectionModel}
                    onSelectionModelChange={onSelectionModelChange}
                    onRowsScrollEnd={handlePageLoad}
                    components={{
                        // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
                        LoadingOverlay: loadingOverlay,
                        // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
                        NoRowsOverlay: noRowsOverlay,
                        // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
                        Toolbar: gridToolbar,
                    }}
                />
            </DataGridListScrollBox>
            <Dialog
                open={openResolveErrorsConfirm}>
                <ConfirmationPrompt
                    heading="Resolve Selected Error Logs"
                    message={getResolveErrorsConfirmationMessage()}
                    handleConfirm={onResolveErrorsConfirm}
                    handleReject={onResolveErrorDialogClose}
                    isBusy={isSettingErrorsResolved}
                    errorMessage={resolveErrorStatusErrorMessage}
                />
            </Dialog>
        </MainContentBox>
    );
}

export default ErrorLogs;